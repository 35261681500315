const selling_choices = [
  {
    id: 50,
    title: "Nesting",
    description: "I'm settling into my home",
  },
  {
    id: 150,
    title: "Investing",
    description: "I'm upgrading my home",
  },
  {
    id: 250,
    title: "Resting",
    description: "I'm enjoying my home",
  },
  {
    id: 350,
    title: "Questing",
    description: "I'm searching for a new home",
  },
];

export default selling_choices;
