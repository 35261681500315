import {
  fetchPropertyById,
  fetchPropertyLikedStatus,
  updatePropertyViaApi,
} from "@src/api/property.js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthTokenFromStorage } from "@src/utils/misc";
import { uploadPhoto } from "@src/utils/photos";
import { getLatestProposalOfProperty } from "@src/api/proposal";

export const getRoutedPropertyById = createAsyncThunk(
  "property/getRoutedPropertyById",
  async (propertyId, { getState, rejectWithValue }) => {
    const state = getState();
    if (!propertyId) {
      return null;
    }
    const existingProperty = state.search.properties.find((property) => {
      return property.id === parseInt(propertyId);
    });

    if (existingProperty) {
      return existingProperty;
    } else {
      try {
        let response = await fetchPropertyById(propertyId);
        const isLoggedIn = !!getAuthTokenFromStorage();
        if (isLoggedIn) {
          const likedStatus = await fetchPropertyLikedStatus(propertyId);
          response.liked = likedStatus?.liked;

          const latestPaidProposal = await getLatestProposalOfProperty({
            propertyId,
          });
          if (latestPaidProposal?.id) {
            response.latestPaidProposal = latestPaidProposal;
          }
        }

        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        return rejectWithValue(message);
      }
    }
  },
);

export const getDisplayPropertyById = createAsyncThunk(
  "property/getDisplayPropertyById",
  async (propertyId, { rejectWithValue }) => {
    if (!propertyId) {
      return null;
    }

    try {
      let response = await fetchPropertyById(propertyId);
      const isLoggedIn = !!getAuthTokenFromStorage();
      if (isLoggedIn) {
        const likedStatus = await fetchPropertyLikedStatus(propertyId);
        response.liked = likedStatus?.liked;

        const latestPaidProposal = await getLatestProposalOfProperty({
          propertyId,
        });
        if (latestPaidProposal?.id) {
          response.latestPaidProposal = latestPaidProposal;
        }
      }

      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return rejectWithValue(message);
    }
  },
);

export const updateProperty = createAsyncThunk(
  "property/updateProperty",
  async (propertyPayload, { rejectWithValue }) => {
    const { propertyData, isDraft } = propertyPayload;

    const newPropertyData = {
      property_id: propertyData.id,
      beds: propertyData.owner_given_bedrooms,
      gross_area: propertyData.owner_given_area,
      baths: propertyData.owner_given_bathrooms,
      last_sale_year: "",
      last_sale_amount: "",
      owner_expected_value: propertyData.owner_expected_value,
      owner_ownership_cycle: propertyData.owner_ownership_cycle,
      owner_selling_choice: propertyData.owner_selling_choice,
      owner_blurb: propertyData.owner_blurb,
      owner_headline: propertyData.owner_headline,
      owner_given_style: propertyData.owner_given_style,
      owner_perks: propertyData.owner_perks,
      photos: propertyData.photos,
    };
    if (isDraft) {
      newPropertyData.draft = true;
    }

    try {
      return await updatePropertyViaApi(newPropertyData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return rejectWithValue(message);
    }
  },
);

export const updatePropertyMainImage = createAsyncThunk(
  "property/updatePropertyMainImage",
  async (payload, { rejectWithValue }) => {
    const { file, type, propertyId } = payload;

    try {
      return await uploadPhoto({ file, type, propertyId });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return rejectWithValue(message);
    }
  },
);

const searchService = {
  getRoutedPropertyById,
  getDisplayPropertyById,
  updateProperty,
  updatePropertyMainImage,
};

export default searchService;
