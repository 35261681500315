import { useState, useEffect, useMemo } from "react";
import { MdArrowBack, MdClose, MdSearch } from "react-icons/md";
import UnlistedLogo from "@src/assets/svgs/UnlistedLogo";
import AboutIcon from "@src/assets/svgs/AboutIcon";
import MyHomeIcon from "@src/assets/svgs/MyHomeIcon";
import MoreIcon from "@src/assets/svgs/MoreIcon";
import DiscoverIcon from "@src/assets/svgs/DiscoverIcon";
import FAQIcon from "@src/assets/svgs/FAQIcon";
import SignInIcon from "@src/assets/svgs/SignInIcon";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NavItem from "@src/components/NavItem";
import { AnimatePresence, motion, useAnimationControls } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  openSearchPanel,
  setAllRandomAndSearchPropertyLikedStatusAndOwner,
} from "@src/store/features/search/slice";
import MenuIcon from "@src/assets/svgs/MenuIcon";
import { removeUserInfo, resetTours } from "@src/store/features/user/slice.js";
import { logOut } from "@src/api/user.js";
import CardsViewMenuIcon from "@src/assets/svgs/CardsViewMenuIcon";
import { getUsersClaimedHomes } from "@src/store/features/user/service.js";
import { toast } from "react-toastify";
import LogOutIcon from "@src/assets/svgs/LogOutIcon";
import EmailIcon from "@src/assets/svgs/EmailIcon";

import * as Sentry from "@sentry/react";
import FeedbackMenuIcon from "@src/assets/svgs/FeedbackMenuIcon.jsx";
import FavoriteMenuIcon from "@src/assets/svgs/FavoriteMenuIcon.jsx";
import ClaimHomeMenuIcon from "@src/assets/svgs/ClaimHomeMenuIcon.jsx";
import {
  getRoutedPropertyById,
  updateProperty,
} from "@src/store/features/property/service.js";

function Header({ theme: themeProp, mode }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const transparentThemePaths = ["/discover/cards"];
  const shouldNotRenderHeader =
    location.pathname.includes("/invitations") ||
    // location.pathname.includes("/profile") ||
    location.pathname.includes("/conversation/");
  const isFindMyHome = location.pathname.startsWith("/claim");
  const darkThemePaths = ["/profile"];

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const homes = useSelector((state) => state.user.homes);

  const appVersion = useSelector((state) => state.user.appVersion);
  const appBuildDate = useSelector((state) => state.user.appBuildDate);
  const isRunningServiceWorker = useSelector(
    (state) => state.user.isRunningServiceWorker,
  );
  const isRunningInStandAlone = useSelector(
    (state) => state.user.isRunningInStandAlone,
  );
  const isRunningInNativeApp = useSelector(
    (state) => state.user.isRunningInNativeApp,
  );

  const [theme, setTheme] = useState("light");
  const [showAppInfo, setShowAppInfo] = useState(false);
  const [showInfoTapCount, setShowInfoTapCount] = useState(0);

  const handleToggleAppInfo = () => {
    if (showAppInfo) {
      setShowAppInfo(false);
      setShowInfoTapCount(0);
    } else {
      setShowInfoTapCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (showInfoTapCount > 4) {
      setShowAppInfo(true);
    }
  }, [showInfoTapCount]);

  const containerVariants = {
    close: {
      x: "-100%",
      transition: {
        type: "easeIn",
        damping: 0,
        duration: 0.125,
      },
    },
    open: {
      x: 0,
      transition: {
        type: "easeIn",
        damping: 0,
        duration: 0.125,
      },
    },
  };

  const blockerVariants = {
    close: {
      opacity: "0",
      pointerEvents: "none",
      transition: {
        type: "easeIn",
        damping: 0,
        duration: 0.125,
      },
    },
    open: {
      opacity: 1,
      pointerEvents: "auto",
      transition: {
        type: "easeIn",
        damping: 0,
        duration: 0.125,
      },
    },
  };

  const containerControls = useAnimationControls();
  const blockerControls = useAnimationControls();
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (isOpen) {
      containerControls.start("close");
      blockerControls.start("close");
    } else {
      containerControls.start("open");
      blockerControls.start("open");
    }
  }, [isOpen]);

  useEffect(() => {
    if (themeProp) {
      setTheme(themeProp);
      return;
    }
    if (
      location.pathname === "/" ||
      transparentThemePaths.find((path) => location.pathname.startsWith(path))
    ) {
      setTheme("transparent");
    } else if (
      darkThemePaths.find((path) => location.pathname.startsWith(path))
    ) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, [location]);

  useEffect(() => {
    if (homes?.length < 2 && isLoggedIn) {
      dispatch(getUsersClaimedHomes({ page: 1, page_size: 2 }));
    }
  }, [isLoggedIn]);

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchOpen = () => {
    dispatch(openSearchPanel());
  };

  const handleLogOut = async () => {
    handleOpenClose();

    try {
      await logOut();
      dispatch(removeUserInfo());
      dispatch(setAllRandomAndSearchPropertyLikedStatusAndOwner());
      navigate("/");
      toast.success("You've successfully logged out");
    } catch (error) {
      toast.error("Couldn't log you out");
      console.log(error);
    }
  };

  const handleFeedbackClick = async () => {
    const feedbackIntegration = Sentry.getFeedback();
    if (feedbackIntegration) {
      const form = await feedbackIntegration.createForm();
      form.appendToDom();
      form.open();
    } else {
      console.error("Feedback integration is not initialized");
    }
  };

  const throwError = () => {
    throw new Error("This is an intentionally generated error");
  };

  const handleRestOnboarding = async () => {
    //if user has homes get the first home id
    const homeId = homes?.[0]?.id;
    //fetch the home from db
    if (!homeId) {
      dispatch(resetTours());
      return;
    }
    const response = await dispatch(getRoutedPropertyById(homeId));
    console.log("response", response);
    const property = response?.payload;

    if (property) {
      const newProperty = { ...property };
      newProperty["owner_given_style"] = null;
      newProperty["owner_perks"] = null;
      newProperty["owner_ownership_cycle"] = 0;
      newProperty["owner_expected_value"] = null;

      await dispatch(
        updateProperty({ propertyData: newProperty, isDraft: true }),
      );
      dispatch(resetTours());
    }

    //dispatch(resetTours());
  };

  const checkForServiceWorkerUpdate = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (let registration of registrations) {
            console.log("Checking for service worker update:", registration);
            registration.update();
          }
        })
        .catch((error) => {
          console.error("Error getting service worker registrations:", error);
        });
    }
  };
  const killServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (let registration of registrations) {
            registration.unregister().then((boolean) => {
              if (boolean) {
                console.log("Service worker unregistered:", registration);
                // Optionally reload the page
                // window.location.reload();
              } else {
                console.log(
                  "Service worker unregistration failed:",
                  registration,
                );
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error getting service worker registrations:", error);
        });
    }

    if ("caches" in window) {
      caches
        .keys()
        .then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName).then((boolean) => {
              if (boolean) {
                console.log("Cache deleted:", cacheName);
              } else {
                console.log("Cache not deleted:", cacheName);
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error getting cache names:", error);
        });
    }
  };

  const homeOptions = useMemo(() => {
    if (homes?.length)
      return {
        icon: MyHomeIcon,
        label: `My Home${homes.length > 1 ? "s" : ""}`,
        redirectLink: `/profile/${homes?.[0]?.id}`,
      };

    return {
      icon: ClaimHomeMenuIcon,
      label: "Claim your home",
      redirectLink: "/claim",
    };
  }, [homes?.length]);

  return (
    <div className={shouldNotRenderHeader ? "hidden" : "block"}>
      <header
        className={`fixed z-20 flex w-full items-start ${theme === "transparent" ? "bg-transparent" : theme === "dark" ? "md:white fill-white text-white md:fill-black md:text-black" : `${isFindMyHome ? "bg-[#F7F7F7]" : "bg-white"}`}`}
      >
        <div className="relative z-10 flex w-full flex-row items-center justify-between px-3 py-4 pb-2 pt-6">
          {mode === "modal" ? (
            <button
              className="rounded-full bg-transparent p-2 duration-300 md:hover:bg-gray-200"
              onClick={() => {
                //remove the the showing property from the search params
                setSearchParams({});
              }}
            >
              <MdArrowBack className="h-6 w-6 fill-current" />
              {/*<img className="w-6 fill-current" src={menuIcon} />*/}
            </button>
          ) : (
            <button
              className="rounded-full bg-transparent p-2 duration-300 md:hover:bg-gray-200"
              onClick={handleOpenClose}
            >
              <MenuIcon className="h-6 w-6 fill-current" />
              {/*<img className="w-6 fill-current" src={menuIcon} />*/}
            </button>
          )}

          <UnlistedLogo
            onClick={handleToggleAppInfo}
            className={"w-28 fill-current"}
          />

          <button
            className="rounded-full bg-transparent p-2 duration-300 md:hover:bg-gray-200"
            onClick={handleSearchOpen}
          >
            <MdSearch className="h-[1.5rem] w-[1.5rem] fill-current" />
          </button>
        </div>
        {theme === "dark" && (
          <div className="absolute left-0 top-0 h-44 w-full bg-gradient-to-b from-black/80 md:hidden"></div>
        )}
      </header>
      <motion.div
        variants={containerVariants}
        initial="close"
        animate={containerControls}
        className="fixed top-0 z-40 flex h-dvh w-[80%] max-w-80 -translate-y-full flex-col justify-between bg-[#3A3D3B] font-attention text-xl text-[#CCCCCC]"
      >
        <div className="flex flex-row items-center gap-4 px-5 py-6">
          <button className="rounded-full bg-transparent p-2 duration-300 md:hover:bg-[#B2A585]/20">
            <MdClose className="h-6 w-6 fill-white" onClick={handleOpenClose} />
          </button>
          <UnlistedLogo className={"w-28 fill-white"} />
        </div>

        <nav className="w-full">
          <ul className="flex w-full flex-col" onClick={handleOpenClose}>
            {/*<NavItem to="/" icon={CardsViewMenuIcon}>*/}
            {/*  <span className="sm:hidden">Swipe</span>*/}
            {/*  <span className="hidden sm:block">Rate</span>*/}
            {/*</NavItem>*/}
            <NavItem
              to="/discover/cards"
              icon={DiscoverIcon}
              additionalActivePaths={["/discover/list", "/discover/map"]}
            >
              Discover
            </NavItem>
            <NavItem to={homeOptions?.redirectLink} icon={homeOptions.icon}>
              {homeOptions?.label}
            </NavItem>
            {isLoggedIn && (
              <NavItem
                to="/liked"
                icon={(props) => (
                  <FavoriteMenuIcon className={`${props?.className} h-6 w-6`} />
                )}
              >
                Homes I like
              </NavItem>
            )}

            {isLoggedIn && (
              <NavItem
                to="/conversation_list"
                icon={(props) => (
                  <EmailIcon className={`${props?.className} h-6 w-6`} />
                )}
              >
                Chats
              </NavItem>
            )}
            {!isLoggedIn && (
              <NavItem to="/about" icon={AboutIcon}>
                About
              </NavItem>
            )}

            {!isLoggedIn && (
              <NavItem to="/faqs" icon={FAQIcon}>
                FAQs
              </NavItem>
            )}

            <NavItem onClick={handleFeedbackClick} icon={FeedbackMenuIcon}>
              Give feedback
            </NavItem>
            <NavItem to="/more" icon={MoreIcon}>
              More
            </NavItem>
          </ul>
        </nav>
        {isLoggedIn ? (
          <div
            className="flex items-center gap-x-3 px-6 py-8"
            onClick={handleLogOut}
          >
            <LogOutIcon className="w-5 fill-[#A0A0A0]/65" />
            Log Out
          </div>
        ) : (
          <div className="py-5" onClick={handleOpenClose}>
            <NavItem to="/signin" icon={SignInIcon}>
              Sign In
            </NavItem>
          </div>
        )}
      </motion.div>
      <motion.div
        variants={blockerVariants}
        initial="closed"
        animate={blockerControls}
        className="fixed top-0 z-30 h-16 h-full w-full bg-[#3A3D3B]/40 opacity-0"
        onClick={handleOpenClose}
      ></motion.div>
      <AnimatePresence>
        {showAppInfo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed z-50 h-full w-full bg-black/40"
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.8, x: "-50%", y: "-50%" }}
              animate={{ opacity: 1, scale: 1, x: "-50%", y: "-50%" }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="absolute left-1/2 top-1/2 flex w-[min(calc(100%-3rem),28rem)] -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-4 rounded-[16px] bg-[#E6E6E6] p-6 shadow-lg"
            >
              <p className="text-center">{window.location.hostname}</p>
              <p className="text-center">
                Build: {appVersion}@{appBuildDate}
              </p>
              <p className="text-center">
                {isRunningServiceWorker ? (
                  <span>Service worker IS running</span>
                ) : (
                  <span>NO service worker running</span>
                )}
                <br />
                {isRunningInStandAlone ? (
                  <span>IN stand alone mode</span>
                ) : (
                  <span>NOT in stand alone mode</span>
                )}
                <br />
                {isRunningInNativeApp ? (
                  <span>IS Native App</span>
                ) : (
                  <span>NOT Native App</span>
                )}
              </p>
              <p className="text-center">
                {isLoggedIn ? (
                  <span>User is logged in</span>
                ) : (
                  <span>User is not logged in</span>
                )}
              </p>
              <button
                className="w-full rounded-md bg-primary-500 p-3 px-6 text-white transition-all hover:brightness-110 hover:filter"
                onClick={throwError}
              >
                Generate Error
              </button>
              <button
                className="w-full rounded-md bg-primary-500 p-3 px-6 text-white transition-all hover:brightness-110 hover:filter"
                onClick={checkForServiceWorkerUpdate}
              >
                Check for Service Worker Update
              </button>
              <button
                className="w-full rounded-md bg-primary-500 p-3 px-6 text-white transition-all hover:brightness-110 hover:filter"
                onClick={killServiceWorker}
              >
                Unregister Service Worker
              </button>
              <button
                className="w-full rounded-md bg-primary-500 p-3 px-6 text-white transition-all hover:brightness-110 hover:filter"
                onClick={handleRestOnboarding}
              >
                Reset Onboarding
              </button>
              <button
                className="w-full rounded-md bg-primary-500 p-3 px-6 text-white transition-all hover:brightness-110 hover:filter"
                onClick={handleToggleAppInfo}
              >
                Close
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Header;
