import React, { useEffect, useRef, useState } from "react";
import { formatNumber, unformatNumber } from "@src/utils/misc.js";
import { MAX_HOUSE_PRICE } from "@src/utils/constants";
import { toast } from "react-toastify";

function PropertyProfileEditorCurrencyInput({
  id,
  title,
  value = "",
  onChange,
  placeholder = "",
}) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  // Sync the prop value with internal state whenever the value changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setInputValue(`$${formatNumber(value || 0)}`);
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Only allow numeric input
    const numericValue = value.replace(/[^0-9.]/g, ""); // This keeps numbers and the dot (for decimals)

    // Update internal state with formatted value

    const numericValueAsNumber = Number(numericValue);
    if (numericValueAsNumber > MAX_HOUSE_PRICE) {
      toast.error(`The price cannot exceed ${MAX_HOUSE_PRICE}.`);
      return;
    }

    setInputValue(`$${formatNumber(numericValue)}`);
    // Call the parent onChange handler with the raw numeric value
    if (onChange) {
      onChange(numericValueAsNumber);
    }
  };

  return (
    <>
      <h1 className="mt-36 text-balance p-4 px-8 pt-0 text-center font-attention text-2xl font-light leading-[130%] text-neutral-600 sm:text-3xl md:pt-8">
        {title}
      </h1>
      <div className="mt-10 flex w-[min(calc(100%-5rem),12rem)] flex-row items-center overflow-hidden rounded-md ring-1 ring-neutral-300 transition-all focus:ring-primary-500">
        <input
          ref={inputRef}
          onChange={handleInputChange}
          value={`$${formatNumber(value)}`}
          placeholder={placeholder}
          className="min-w-0 flex-1 p-3 pl-0 text-center font-content text-xl text-neutral-900 outline-none"
          id={id}
          inputMode="numeric"
        />
      </div>
    </>
  );
}

export default PropertyProfileEditorCurrencyInput;
