import React, { useEffect, useState, useRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { setFirstPhotoPromptLastShown } from "@src/store/features/user/slice.js";
import { updatePropertyMainImage } from "@src/store/features/property/service.js";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import { cacheImage } from "@src/store/features/imageCache/slice.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import CameraIllustration from "@src/assets/camera_illustration.png";
import { UPLOAD_PHOTO_TYPE } from "@src/utils/constants";

export default function AddFirstPhotoPrompt({ property }) {
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    homes,
    onboardingSlideshowComplete,
    firstPhotoPromptLastShown,
  } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const fileInputRef = useRef(null);
  const [hasCoverPhoto, setHasCoverPhoto] = useState(property.cover_photo_url);

  const timeSinceLastPrompt = firstPhotoPromptLastShown
    ? new Date() - new Date(JSON.parse(firstPhotoPromptLastShown))
    : 0;

  const minutesSinceLastPrompt = timeSinceLastPrompt
    ? Math.floor(timeSinceLastPrompt / 60000) // Divide by 60000 to get minutes
    : 0;

  const daysSinceLastPrompt = timeSinceLastPrompt
    ? Math.floor(timeSinceLastPrompt / 86400000) // Divide by 86400000 to get days
    : 0;

  // Handle the image file selection
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      // dispatch(invalidateImage(property.id));

      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await dispatch(
        updatePropertyMainImage({
          file: event.target.files[0],
          type: UPLOAD_PHOTO_TYPE.PROPERTY_COVER,
          propertyId: property.id,
        }),
      );

      dispatch(
        cacheImage({
          propertyId: property.id,
          imageUrl: response.payload.get_url,
        }),
      );
      if (response.payload.get_url) {
        // setIsUploading(false);
        dispatch(
          setPropertyPropValue({
            key: "cover_photo_url",
            value: response.payload.get_url,
          }),
        );
      }

      dismissPrompt(true);
    }
  };

  const handleAddFirstPhoto = () => {
    fileInputRef.current.click();
  };

  const dismissPrompt = () => {
    setOpen(false);
  };

  const handleMaybeLater = () => {
    dismissPrompt(false);
  };

  useEffect(() => {
    const isEditing = searchParams.get("edit");
    const isHomeOwner = homes.some((home) => home.id === property.id);
    const hasCoverPhoto = property.cover_photo_url;
    const canShowFirstTime = !firstPhotoPromptLastShown;
    const canShowAgain = minutesSinceLastPrompt > 59; //Show again after 1 hour

    const show =
      onboardingSlideshowComplete &&
      isHomeOwner &&
      isLoggedIn &&
      !isEditing &&
      (canShowFirstTime || canShowAgain) &&
      hasCoverPhoto === null;

    // setOpen(true);
    setTimeout(() => {
      if (show)
        dispatch(setFirstPhotoPromptLastShown(JSON.stringify(new Date())));
      setOpen(show);
    }, 1000);
  }, [onboardingSlideshowComplete, homes]);
  return (
    <>
      {!property.cover_photo_url ? (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay
              onClick={() => setOpen(false)}
              className="fixed inset-0 z-50 bg-black/40 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]"
            />
            <Dialog.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              className="fixed inset-0 z-50 flex flex-col data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms]"
            >
              <VisuallyHidden>
                <Dialog.Title>Please edit your profile</Dialog.Title>
                <Dialog.Description>
                  Click the button to launch the profile editor
                </Dialog.Description>
              </VisuallyHidden>
              <div className="absolute bottom-5 left-1/2 flex w-[min(calc(100%-2rem),25rem)] -translate-x-1/2 flex-col items-center justify-center overflow-hidden text-balance rounded-3xl bg-[#FAFAFA] px-8 pb-5 pt-7 shadow-2xl md:bottom-auto md:top-1/2 md:-translate-y-1/2">
                <div
                  className={`flex w-full flex-col items-center justify-center ${isUploading ? "pointer-events-none opacity-100" : ""}`}
                >
                  {isUploading && (
                    <>
                      <div className="absolute inset-0 z-20 flex flex-col items-center justify-center gap-2 bg-white/90 text-neutral-900">
                        <div className="absolute inset-0"></div>
                        <div className="z-10 h-8 w-8 animate-spin rounded-full border-4 border-neutral-200 border-r-primary-500"></div>
                        <span className="z-50 animate-pulse text-neutral-500">
                          Updating image...
                        </span>
                      </div>
                    </>
                  )}
                  <div className="flex aspect-square h-20 w-20 shrink-0 items-center justify-center rounded-full bg-sage-200">
                    <img
                      className="w-12"
                      src={CameraIllustration}
                      alt="Camera Illustration"
                    />
                  </div>
                  <p className="mt-3 max-w-[40ch] text-pretty text-center font-attention text-xl text-neutral-600">
                    Let&apos;s add a cover photo that captures your home’s
                    unique charm.
                  </p>
                  <button
                    onClick={handleAddFirstPhoto}
                    className="mt-7 w-full max-w-[80%] rounded-full bg-[#8E9991] p-3 px-8 text-base text-white shadow-xl shadow-primary-500/20 transition-all md:hover:bg-[#818d84]"
                  >
                    Add photo
                  </button>
                  <button
                    onClick={handleMaybeLater}
                    className="mt-4 w-full p-4 py-1 text-sm text-[#808080] underline md:hover:text-[#666666]"
                  >
                    Maybe later
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      ) : null}
    </>
  );
}
