import { useState } from "react";

export default function UploadPhotosImageTile({
  photo,
  index,
  handleRemovePhoto,
}) {
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  return (
    <div className="relative h-24 w-24">
      <img
        src={photo.url}
        alt="Uploaded preview"
        className="h-full w-full rounded-md object-cover"
        onLoad={() => setIsLoadingImage(false)}
      />
      {!(photo.url.startsWith("blob:") && photo.uploading) && (
        <button
          className="absolute right-1 top-1 h-6 w-6 rounded-full bg-black bg-opacity-50 text-sm text-white"
          onClick={() => handleRemovePhoto(index)}
        >
          ✕
        </button>
      )}
      {photo.url.startsWith("blob:") && photo.uploading ? (
        <div className="absolute top-0 z-10 flex h-full w-full animate-pulse items-center justify-center rounded-md bg-black/60 text-sm text-white">
          Uploading
        </div>
      ) : null}
      {isLoadingImage ? (
        <div className="absolute top-0 -z-10 flex h-full w-full animate-pulse rounded-md bg-black/40" />
      ) : null}
    </div>
  );
}
