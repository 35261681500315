import { useRef, useState, useEffect } from "react";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { toast } from "react-toastify";
import { IMAGE_SIZE_LIMIT } from "@src/utils/constants";
import { deleteClaimedPropertyPhoto } from "@src/utils/photos";
import UploadPhotosImageTile from "./components/uploadPhotosImageTile";

function PropertyProfileEditorUploadPhotos({
  id,
  title,
  photos,
  onChange,
  onDelete,
  isUploadDisabled,
  isUploading,
}) {
  const [photoURLs, setPhotoURLs] = useState(photos);
  const inputRef = useRef(null);

  const onClickSelectImages = () => {
    if (isUploading) {
      toast.info("Please wait while your photos are being uploaded.");
      return;
    }
    if (!isUploadDisabled) inputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const currentPhotos = new Set(
      photoURLs.map((urlObj) => urlObj.name || urlObj.url.split("/").pop()),
    );

    const allFiles = Array.from(e.target.files);

    let filteredLarge = "";
    let multipleLarge = false;
    let filteredDuplicate = "";
    let multipleDuplicate = false;
    /**
     * Filter out all images which are either
     * greater than the image limit (10MB)
     * or a duplicate
     */
    const files = allFiles?.filter((file) => {
      const isSmallerThanLimit = file?.size / (1000 * 1000) < IMAGE_SIZE_LIMIT;
      if (!isSmallerThanLimit) {
        if (filteredLarge?.length) multipleLarge = true;
        filteredLarge += `${filteredLarge?.length ? ", " : ""}${file?.name}`;
      }

      const isDuplicate = currentPhotos.has(file.name);
      if (isDuplicate) {
        if (filteredDuplicate?.length) multipleDuplicate = true;
        filteredDuplicate += `${filteredDuplicate?.length ? ", " : ""}${file?.name}`;
      }

      return isSmallerThanLimit && !isDuplicate;
    });

    if (filteredLarge?.length) {
      toast.error(
        `Could not upload ${filteredLarge} as ${multipleLarge ? "they are" : "it is"} beyond the ${IMAGE_SIZE_LIMIT}MB limit`,
      );
    }

    if (filteredDuplicate?.length) {
      toast.error(
        `The following file${multipleDuplicate ? "s" : ""} already exist${!multipleDuplicate ? "s" : ""}: ${filteredDuplicate}`,
      );
    }

    const totalFilesCount = photoURLs.length + files.length;
    if (totalFilesCount > 20) {
      toast.error("You can upload a maximum of 20 images.");
      return;
    }
    const photoBlobURLs = files?.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
    }));

    if (files?.length) {
      setPhotoURLs((prevPhotoURLs) => [...prevPhotoURLs, ...photoBlobURLs]);
      onChange(files);
    }
  };

  const handleRemovePhoto = async (index) => {
    const newPhotos = photoURLs.filter((_, i) => i !== index);
    setPhotoURLs(newPhotos);
    const selectedPhotoURL = photoURLs[index];
    if (!selectedPhotoURL.url.startsWith("blob:")) {
      try {
        const response = await deleteClaimedPropertyPhoto(selectedPhotoURL.url);
        if (response === 200) {
          toast.success("Photo removed successfully");
        }
      } catch (error) {
        console.error("Failed to delete photo", error);
      }
    } else {
      toast.success("Photo deleted Successfully");
      onDelete(selectedPhotoURL.name);
    }
  };

  useEffect(() => {
    setPhotoURLs(photos);
  }, [photos]);

  return (
    <div className="flex max-w-md flex-col items-center justify-center">
      <h1
        className="text-balance p-2 px-8 text-center font-attention text-2xl font-light leading-[130%] text-neutral-600 sm:text-3xl md:pt-8"
        htmlFor={id}
      >
        {title}
      </h1>
      <p className="mt-4 text-center text-sm text-neutral-600">
        Add a new image or tap any item to change
      </p>
      <div className="mt-6 flex max-h-[calc(100vh-230px)] justify-center overflow-y-auto px-6">
        <div className="flex flex-wrap justify-center gap-4">
          <div
            className={`flex h-24 w-24 cursor-pointer items-center justify-center rounded-md border border-dotted border-grey-400 bg-neutral-100 ${
              isUploadDisabled ? "cursor-not-allowed opacity-30" : ""
            }`}
            onClick={onClickSelectImages}
          >
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              multiple
              ref={inputRef}
              className="hidden"
              onChange={handleFileUpload}
            />
            <span className="text-grey-400">
              <MdOutlineAddAPhoto
                style={{ width: "31.67px", height: "28.5px" }}
              />
            </span>
          </div>
          {photoURLs?.map((photo, index) => (
            <UploadPhotosImageTile
              key={photo?.url}
              photo={photo}
              index={index}
              handleRemovePhoto={handleRemovePhoto}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PropertyProfileEditorUploadPhotos;
