import CryptoJS from "crypto-js";
import { patchRequest, postRequest, deleteRequest } from "./axiosClient";
import { UPLOAD_PHOTO_TYPE } from "./constants";
import { toast } from "react-toastify";

const getmd5 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (fileEvent) => {
      let binary = CryptoJS.lib.WordArray.create(fileEvent.target.result);
      const md5 = CryptoJS.MD5(binary);
      resolve(md5);
    };
    reader.onerror = () => {
      reject("file reader error");
    };
    reader.readAsArrayBuffer(file);
  });
};

const getFileChecksum = async (file) => {
  const md5 = await getmd5(file);
  const checksum = md5.toString(CryptoJS.enc.Base64);
  return checksum;
};

const createPresignedUrl = async ({
  fileName,
  fileType,
  byteSize,
  checksum,
  type,
  propertyId,
}) => {
  // Note: we don't send all of these options at the moment because the AWS Ruby SDK doesn't take in some of them
  const content_type = fileType;
  let options = {
    filename: fileName,
    byte_size: byteSize,
    checksum,
    content_type,
    metadata: {
      type: type,
    },
    directory: type,
    propertyId,
  };

  let response = postRequest({ url: "/photo_presigned_url", payload: options });
  if (response.status !== 200) return response;
  return response;
};

const createPresignedUrlsBulk = async ({ directory, propertyId, files }) => {
  const payload = {
    directory: directory,
    propertyId,
    files: files.map((file) => ({
      filename: file.name,
    })),
  };
  const response = await postRequest({
    url: "/photo_presigned_url_bulk",
    payload,
  });

  if (response.status !== 200) return response;
  return response;
};

export const uploadPhoto = async ({ file, type, propertyId = 0 }) => {
  const checksum = await getFileChecksum(file);
  if (
    file.type !== "image/png" &&
    file.type !== "image/jpg" &&
    file.type !== "image/jpeg" &&
    file.type !== "image/gif"
  ) {
    return "invalid file type";
  }
  const presignedUrlParams = await createPresignedUrl({
    fileName: file.name,
    fileType: file.type,
    byteSize: file.size,
    checksum,
    type,
    propertyId,
  });

  const s3options = {
    method: "PUT",
    body: file,
  };

  let s3response = await fetch(presignedUrlParams.data.url, s3options);

  if (s3response.status !== 200) return false;

  if (type === UPLOAD_PHOTO_TYPE.PROPERTY_COVER) {
    const response = await patchRequest({
      url: `/properties/${propertyId}/quiz/`,
      payload: {
        photo: presignedUrlParams.data.get_url,
      },
    });
    if (response.status !== 200) {
      return false;
    } else {
      return presignedUrlParams.data;
    }
  }

  if (
    type === UPLOAD_PHOTO_TYPE.USER_PROFILE ||
    UPLOAD_PHOTO_TYPE.CLAIMED_PROPERTY_PHOTOS
  )
    return presignedUrlParams?.data?.get_url;
};

export const uploadPhotosBulk = async ({
  propertyId = 0,
  files,
  directory,
}) => {
  const presignedUrlParams = await createPresignedUrlsBulk({
    directory,
    propertyId,
    files,
  });

  if (presignedUrlParams.status !== 200) {
    throw "Failed to retrieve presigned URLs";
  }

  const results = await Promise.all(
    files.map(async (file, index) => {
      const presignedUrl = presignedUrlParams.data.urls[index].put_url;

      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/gif"
      ) {
        toast.error(`Invalid file type of ${file.name}`);
        return null; // Skip this file
      }

      const s3options = {
        method: "PUT",
        body: file,
      };

      try {
        const s3response = await fetch(presignedUrl, s3options);

        if (s3response.status !== 200) {
          toast.error(`Failed to upload ${file.name}`);
          return null; // Skip this file
        }

        if (directory === UPLOAD_PHOTO_TYPE.CLAIMED_PROPERTY_PHOTOS) {
          return {
            filename: file.name.substring(0, file.name.lastIndexOf(".")),
            ext: file.name.substring(file.name.lastIndexOf(".")),
            url: presignedUrlParams.data.urls[index].get_url,
          };
        }
      } catch (error) {
        toast.error(`Error uploading ${file.name}: ${error.message}`);
        return null; // Skip this file
      }
    }),
  );

  // Filter out any null results (for skipped files)
  return results.filter((result) => result !== null);
};

export const deleteClaimedPropertyPhoto = async (url) => {
  try {
    const response = await deleteRequest({
      url: "/api/v1/claimed_property_photos",
      params: {
        url: url,
      },
    });

    return response.status;
  } catch (error) {
    throw error.response.data;
  }
};
